<div class="modal-header">
  <h5 class="modal-title" *ngIf="isDepositMode">{{'depositToPool' | translate}}</h5>
  <h5 class="modal-title" *ngIf="!isDepositMode">{{'withdrawFromPool' | translate}}</h5>
  <div type="button" class="btn-close" [mat-dialog-close]="true"></div>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-lg-6 mb-4">
      <div class="subtitle-2 text-muted">{{'your' | translate}} {{item.stakingTokenSymbol}}</div>
      <div class="subtitle-2">{{getIntegerPart(balance)}} {{item.stakingTokenSymbol}}</div>
    </div>
    <div class="col-lg-6 mb-4">
      <div class="subtitle-2 text-muted">{{'depositedTokens' | translate}}</div>
      <div class="subtitle-2">{{currentStakeAmount| shiftDecimals:-stakingTokenDecimals| number: '1.0-2'}} {{item.stakingTokenSymbol}}</div>
    </div>
  </div>

  <div class="d-md-flex align-items-center mb-4" *ngIf="isDepositMode">  
    <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
      <img src="../../assets/images/tokens/blp.svg" alt="">
      <mat-label>{{item.stakingTokenSymbol}}</mat-label>
      <input matInput
             type="number"
             min="0"
             max="{{getIntegerPart(balance)}}"
             name="selectedAmount"
             [(ngModel)]="depositAmount"
             [disabled]="waiting"
             required>
      <button mat-button color="primary" class="max-btn" (click)="setMaxDepositAmount()" [disabled]="waiting || isApprovedToken">MAX</button>
      <mat-hint class="text-right">{{'available' | translate}} {{getIntegerPart(balance)}}</mat-hint>
    </mat-form-field>
  </div>

  <div class="d-md-flex align-items-center mb-4" *ngIf="!isDepositMode">
    <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
      <img src="../../assets/images/tokens/blp.svg" alt="">
      <mat-label>{{item.stakingTokenSymbol}}</mat-label>
      <input matInput
             type="number"
             min="0"
             max="{{currentStakeAmount| shiftDecimals:-stakingTokenDecimals| number: '1.0-2'}}"
             name="selectedAmount"
             [(ngModel)]="withdrawAmount"
             [disabled]="waiting"
             required>
      <button mat-button color="primary" class="max-btn" (click)="setMaxWithdrawAmount()" [disabled]="waiting || isApprovedToken">MAX</button>
      <mat-hint class="text-right">{{'available' | translate}}: {{currentStakeAmount| shiftDecimals:-stakingTokenDecimals| number: '1.0-2'}}</mat-hint>
    </mat-form-field>
  </div>

  <div class="mb-4" *ngIf="!isDepositMode && (currentPenalty > 0)">
    <div class="subtitle-2 text-muted">{{'unlockingPenalty' | translate}}</div>
    <div class="subtitle-2">-{{penaltyBP/100}}% ({{currentPenalty}} LLAMA)</div>
  </div>
  <!--<div class="mb-4" *ngIf="!isDepositMode">
    <div class="subtitle-2 text-muted">Locking start date</div>
    <div class="subtitle-2">{{userLockingStartTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
  </div>-->

  <div class="btn-group-justified">
    <button mat-raised-button
            color="primary"
            class="btn-icon btn-icon-left"
            [ngClass]="{'btn-loading': waiting&&!approved}"
            [disabled]="waiting || approved"
            (click)="approveClick()">
      <i class="icon icomoon-checkmark"></i>
      {{'approve' | translate}}
    </button>

    <button mat-raised-button
            color="primary"
            *ngIf="isDepositMode"
            [ngClass]="{'btn-loading': waiting&&approved}"
            [disabled]="waiting || !approved"
            (click)="depositClick()">
      {{'deposit' | translate}} {{depositAmount}} {{item.stakingTokenSymbol}}
    </button>

    <button mat-raised-button
            color="primary"
            *ngIf="!isDepositMode"
            [ngClass]="{'btn-loading': waiting&&approved}"
            [disabled]="waiting"
            (click)="withdrawClick()">
      {{'withdraw' | translate}} {{withdrawAmount}} {{item.stakingTokenSymbol}}
    </button>
  </div>
</div>

