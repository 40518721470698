<section class="section-alert" *ngIf="1<0">
  <div class="alert alert-warning" role="alert">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      {{'warningTextAdmins' | translate}}
    </div>
  </div>
</section>

<info-join-deals></info-join-deals>

<section class="dashboard-section p-40">
  <div class="row mb-40 ">
    <div class="col-lg-5 mb-4 mb-lg-0">
      <div class="card p-4 mb-20">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{'wallet' | translate}}
        </div>
        <div class="h5 mb-4">{{userWalletBalance| shiftDecimals:-18| number: '1.0-2'}} LLAMA</div>

        <a routerLink="/staking"
           mat-raised-button
           color="primary"
           class="w-100 mb-2">
          {{'stakeTokens' | translate}}
          <span class="rainbow-border"></span>
        </a>

        <button mat-raised-button color="primary" class="w-100 mb-2 white-card" (click)="showWarningInfoAndLockTokenClick()">
          {{'lockInTokens' | translate}}
          <span class="rainbow-border"></span>
        </button>

        <a routerLink="#"
           mat-raised-button
           color="primary"
           class="w-100">
          {{'buy' | translate}} LLAMA
          <span class="rainbow-border"></span>
        </a>
      </div>

      <div class="card p-4">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-lock me-12 text-muted"></i>
          {{'lockedInTokens' | translate}}
        </div>
        <div class="h5 mb-4">{{lockerUserBalance| shiftDecimals:-18| number: '1.0-2'}} LLAMA</div>

        <div class="__unlock-group mb-4 d-flex align-items-center">
          <button mat-raised-button color="primary" class="__unlock-tokens white-card" (click)="unlockTokenClick()">
            {{'unlockTokens' | translate}}
            <span class="rainbow-border"></span>
          </button>
          <button *ngIf="canTransfer" mat-raised-button color="primary" class="__unlock-tokens white-card" (click)="transferTokenClick()">
            {{'transferTokens' | translate}}
            <span class="rainbow-border"></span>
          </button>
          <button mat-icon-button class="__unlock-info white-card" (click)="openUnlockInfoDlg()">
            <i class="icomoon-info"></i>
          </button>
        </div>
        <div class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted">
          <div>{{'lockingStartDate' | translate}}</div>
          <div>{{userLockingStartTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
        </div>
        <div class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted" *ngIf="0<needToWaitTime">
          <div>{{'timeLockupEnds' | translate}}</div>
          <div>{{getTranslatedShowPeriod(needToWaitTime)}}</div>
        </div>
      </div>

      <div class="card p-4 mt-4" *ngIf="isLockedBalanceExist">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{'oldBlpTokens' | translate}}
        </div>
        <div class="h5 mb-4">{{lockerOldUserBalance| shiftDecimals:-18| number: '1.0-20'}} LLAMA</div>

        <button mat-stroked-button
                class="__unlock-tokens"
                (click)="lockTokenOldClick(false)">
          {{'unlockOldTokens' | translate}}
        </button>
      </div>
    </div>

    <div class="col-lg-7">
      <div class="card min-h-100">
        <div class="card-header">
          <div class="subtitle-1 d-flex align-items-center">
            <i class="icon icomoon-chart-pie me-12 text-muted"></i>
            {{'summary' | translate}}
          </div>
        </div>
        <div class="card-content">
          <div class="caption text-muted mb-1">{{'yourTier' | translate}}</div>
          <div class="__current-tier">
            <img [src]="userTierIndex | imgTierPipe" alt="" />
            <span class="subtitle-1">{{getTranslatedTier(userTierIndex)}}</span>
          </div>

          <div class="progress-group mb-1">
            <div class="progress-legend">
              <div class="progress-legend__value">
                <span *ngIf="!isMaxTier">{{nextTierNeedValue | shiftDecimals:-18| number: '1.0-2'}} LLAMA {{'forNextTier' | translate}}</span>
              </div>
            </div>
            <div class="progress progress-xs">
              <div class="progress-bar"
                   role="progressbar"
                   [style.width.%]="currentTierProgress">
              </div>
            </div>
            <div class="progress-legend">
              <div class="progress-legend__percent">{{ totalUserBlpBalance | shiftDecimals:-18 | number: '1.0-2'}} LLAMA</div>
              <div class="progress-legend__value">{{ nextTierValue | shiftDecimals:-18 | number: '1.0-2'}} LLAMA</div>
            </div>
          </div>
        </div>
        <div class="card-footer">

          <div class="__graph pt-3">
            <div class="__graph__donut d-none d-lg-block">
              <!-- <chart *ngIf="isAuthorized && chartData" type="doughnut" [data]="chartData" [options]="chartOptions"></chart> -->
              <canvas *ngIf="isAuthorized && chartData" style="height: 10vh" baseChart [data]="chartData" [type]="'doughnut'" [options]="doughnutChartOptions" [legend]="false"></canvas>
              <div class="dm-donut --placeholder" *ngIf="!isAuthorized || !chartData"></div>
            </div>
            <div class="__graph__legend">
              <div>
                <div class="legend-item">
                  <span class="legend-item__point" style="background:#131BCB"></span>
                  <span class="legend-item__title"><a routerLink="staking">{{'staking' | translate}} {{stakingPercent}}%</a></span>
                  <span class="legend-item__blp">{{stakingUserBalance | shiftDecimals:-18| number: '1.0-2'}} LLAMA</span>
                </div>
                <!-- <div class="legend-item">
                  <span class="legend-item__point" style="background:#AB57FF"></span>
                  <span class="legend-item__title"><a routerLink="farming">Farming 0%</a></span>
                  <span class="legend-item__blp">0 LLAMA</span>
                </div> -->
                <div class="legend-item">
                  <span class="legend-item__point" style="background:#31C2B9"></span>
                  <span class="legend-item__title">{{'lockedIn' | translate}} {{lockedBalancePercent}}%</span>
                  <span class="legend-item__blp">{{lockedWithBonusValue| shiftDecimals:-18| number: '1.0-2'}} LLAMA</span>
                </div>
              </div>
              <div class="legend-item">
                <span class="legend-item__title">{{'total' | translate}}</span>
                <span class="legend-item__blp">{{totalUserBlpBalance| shiftDecimals:-18| number: '1.0-2'}} LLAMA</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-40" *ngIf="bonusBalance">
    <div class="col-lg-7 mb-4 mb-lg-0 order-lg-2">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{ 'specialTierInstructions' | translate }}
        </div>
        <div class="bonus-tokens-block">
          <div class="bonus-tokens-items">
            <div class="bonus-token-item">
              {{ 'specialTokensItem1' | translate }}
            </div>
            <div class="bonus-token-item">
              {{ 'specialTokensItem2' | translate: {bonusInterval} }}
            </div>
            <div class="bonus-token-item">
              {{ 'specialTokensItem3' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 order-lg-1 d-flex flex-column">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{'specialTokens' | translate}}
        </div>
        <div class="h5 mb-4">{{bonusBalance| shiftDecimals:-18| number: '1.0-20'}} LLAMA</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-7 mb-4 mb-lg-0 order-lg-2">
      <div class="card min-h-100">
        <div class="card-header">
          <div class="subtitle-1 d-flex align-items-center">
            <i class="icon icomoon-chart me-12 text-muted"></i>
            {{'tierProgress' | translate}}
          </div>
        </div>
        <div class="__tiers-list">
          <div class="__tiers-list-item" *ngFor="let currentTier of allTiers; index as i">
            <div class="__tier-card"
                 [ngClass]="{'closed': !isAuthorized || i > nextTierIndex}"
                 (click)="showTierInfoDlg(i)"
                 matTooltipClass="tooltip-md"
                 matTooltipPosition="above"
                 matTooltip="{{getTranslatedTier(i)}} {{'details' | translate}}"
              >
              <div class="__tier-card-logo">
                <img [src]="i | imgTierPipe" alt="" />
              </div>
              <div class="__tier-card-name">{{getTranslatedTier(i)}}</div>
              <div class="__tier-card-progress">
                <div class="progress progress-xs">
                  <div class="progress-bar"
                       role="progressbar"
                       [style.width.%]="getTierPercent(i, currentTier.blpAmount)"></div>
                </div>
              </div>
              <div class="__tier-card-value">
                {{currentTier.blpAmount| shiftDecimals:-18}} LLAMA
              </div>
              <div class="__tier-card-checked" *ngIf="i <= userTierIndex">
                <i class="icon icon-xs icomoon-checked text-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-5 order-lg-1 d-flex flex-column">
      <div class="card benefits-card mb-2">
        <div class="card-header">
          <div class="card-header-title">
            <i class="icon icomoon-award text-muted"></i>
            <div class="subtitle-1">{{'lockerBenefits' | translate}}</div>
          </div>

          <div class="card-header-title">
            <i class="icon icomoon-award text-muted"></i>
            <div class="subtitle-1">{{'stakingBenefits' | translate}}</div>
          </div>
        </div>
        <div class="card-content p-0">
          <table class="table benefits-table">
            <tr>
              <td>{{'joinOurVcIdoInoDeals' | translate}}</td>
              <td>{{'joinOurVcIdoInoDeals' | translate}}</td>
            </tr>
            <tr>
              <td>{{'noRewardsForLocking' | translate}}</td>
              <td>{{'upToAprForStaking' | translate}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="card penalties-card flex-grow-1">
        <div class="card-header">
          <div class="card-header-title">
            <i class="icon icomoon-warning-triangle text-muted"></i>
            <div class="subtitle-1">{{'lockerPenalty' | translate}}</div>
          </div>

          <div class="card-header-title">
            <i class="icon icomoon-warning-triangle text-muted"></i>
            <div class="subtitle-1">{{'stakingPenalty' | translate}}</div>
          </div>
        </div>
        <div class="card-content p-0">
          <table class="table benefits-table">
            <tr>
              <td>{{'penaltyForEarlyUnlock' | translate}} <br/> {{'daysSinceLastJoinedDeal' | translate}}</td>
              <td>{{'penaltyForEarlyWithdraw' | translate}} <br/> {{'untilEndDateOfStakingPool' | translate}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

