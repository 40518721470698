<div (click)="openPoolDetail(item.poolAddress)" class="card-inner show-card-tooltip">
  <div class="card-content">
    <div class="d-flex align-items-center justify-content-between">
      <div class="token-name token-name-inline">
        <div class="token-name__img">
          <img src="../../assets/svg/alpaca-logo.svg"/>
        </div>
        <div class="token-name__info">
          <div class="token-title">{{item.name}}</div>
        </div>
      </div>
    </div>

    <div class="pool-info mt-4">
      <div class="pool-info__row d-flex">
        <div class="label me-auto text-muted">APR</div>
        <div class="value-sm">{{apy| number: '1.0-2'}}%</div>
      </div>
      <div class="pool-info__row d-flex">
        <div class="label me-auto text-muted">{{'tokensLocked' | translate}}</div>
        <div class="value-sm">{{allStakeAmount}} {{item.stakingTokenSymbol}}</div>
      </div>
      <div class="pool-info__row d-flex">
        <div class="label me-auto text-muted">{{'yourStake' | translate}}</div>
        <div class="value-sm">{{currentStakeAmount| shiftDecimals:-item.poolTokenDecimals| number: '1.0-2'}} {{item.stakingTokenSymbol}}</div>
      </div>

      <!--TODO: Calculate penalty -->
      <div class="pool-info__row d-flex">
        <div class="label me-auto text-muted">{{'yourReward' | translate}}</div>
        <div class="value-sm">{{currentPendingReward| shiftDecimals:-item?.poolTokenDecimals| number: '1.0-2'}} {{item.poolTokenSymbol}}</div>
      </div>
      <div class="pool-info__row d-flex">
        <div class="label me-auto text-muted">{{'yourPenalty' | translate}}</div>
        <div class="value-sm">{{penaltyBP}}%</div>
      </div>
    </div>

    <div class="pool-progress mt-4">
      <div class="progress progress-xs">
        <div class="progress-bar" role="progressbar" [style.width.%]="getProgressPercent">
        </div>
      </div>
      <div class="progress-legend d-flex justify-content-between">
        <div class="progress-legend__percent">{{getProgressPercent| number: '1.0-2'}}%</div>
        <div class="progress-legend__value">{{getCurrentPoolTokenAmount| number: '1.0-2'}} / {{item.poolTokenAmount| shiftDecimals:-item.poolTokenDecimals| number: '1.0-2'}}</div>
      </div>
    </div>
  </div>
  <div class="card-tooltip">{{'goToThePool' | translate}}</div>
</div>
