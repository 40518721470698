<button
   (click)="addToGoogleCalendar()"
   *ngIf="isUpcoming && !item.preview"
   mat-icon-button
   class="__add-to-google"
   matTooltip="{{'addToGoogleCalendar' | translate}}"
   matTooltipPosition="above">
  <img src="../../assets/images/google-calendar.svg" alt="">
</button>

<div (click)="navigateToDeal()" class="card-inner show-card-tooltip">
  <div class="card-header">
    <div class="token-name token-name-inline">
      <div class="token-name__img">
        <img [src]="item.logoURL?item.logoURL:'../../assets/images/no-image.svg'"/>
      </div>
      <div class="token-name__info">
        <div class="token-title">{{item.name}}</div>
        <div class="token-symbol">{{item.rewardTokenSymbol}}</div>
      </div>
    </div>
  </div>

  <div class="card-content">
    <div class="row g-0">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="caption text-muted">{{'totalRaise' | translate}}</div>
          <div class="h6 deal-name">
            <!-- {{item.maxDistributedTokenAmount | shiftDecimals: -item.paymentDecimal| number: '1.0-2'}} {{item.rewardTokenSymbol}} -->
            {{firstTokenMaxDistributedTokenAmount| shiftDecimals:-18 | number: '1.0-2'}}
            {{item?.customTokenName?? item?.rewardTokenSymbol}}
          </div>
          <div class="h6 deal-name" *ngIf="item.secondTokenCustomPrice">
            <!-- {{item.maxDistributedTokenAmount | shiftDecimals: -item.paymentDecimal| number: '1.0-2'}} {{item.rewardTokenSymbol}} -->
            {{secondTokenMaxDistributedTokenAmount| shiftDecimals:-18 | number: '1.0-2'}}
            {{item?.secondTokenCustomName?? item?.rewardTokenSymbol}}
          </div>
          <div class="small-text">
            {{getTotalRaiseETH(item)| number: '1.0-2'}} {{item.paymentTokenSymbol}}
          </div>
        </div>
        <div class="col-lg-6">
          <div class="progress deal-progress progress-lg">
            <div class="progress-bar" role="progressbar" [style.width.%]="getDistributedPercent"></div>

            <div class="progress-label" *ngIf="!item.preview && (isUpcoming || isStarted)">
              <span *ngIf="isUpcoming">{{'startsIn' | translate}}</span>
              <span *ngIf="isStarted">{{'timeLeft' | translate}}</span>

              <span class="countdown ms-1">
                <span *ngIf="timerViewDays">{{timerViewDays}} {{'time.day' | translate}}</span>
                <span *ngIf="timerViewHours"> {{timerViewHours}} {{'time.hour' | translate}}</span>
                <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}} {{'time.minute' | translate}}</span>
                <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}} {{'time.second' | translate}}</span>
              </span>
            </div>

            <span class="progress-label" *ngIf="isFinished">{{'finished' | translate}}</span>
          </div>
          <div class="progress-status">
            <div class="progress-status__percent">{{getDistributedPercent| number: '1.0-2'}}%</div>
            <div class="progress-status__value text-muted">
              {{tokensForDistribution| shiftDecimals:-18 | number: '1.0-2'}}/{{item.maxDistributedTokenAmount | shiftDecimals: -18 | number: '1.0-2'}}
            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="card-footer">
    <div class="row">
      <div class="col-6 mb-4 mb-md-0 col-md-4">
        <div class="caption text-muted">
          {{'ratio' | translate}} 1 {{item.paymentTokenSymbol}}
        </div>
        <div class="subtitle-1 mb-1">
          {{getRatioPer1ETH(item) | number: '1.0-2' }}
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="caption text-muted mb-1">{{'type' | translate}}</div>
        <div class="subtitle-1">{{getTranslatedDealTypeName(item.dealType)}}</div>
      </div>
      <div class="col-md-4">
        <div class="caption text-muted mb-1">{{'targetNetwork' | translate}}</div>
        <div class="network-name">
          <img [src]="item.targetNetwork | imgNetworkPipe" class="network-name__img"/>
          <span class="network-name__title">{{item.targetNetwork | networkName }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card-tooltip">{{'goToDeal' | translate}}</div>
</div>
