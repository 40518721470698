import { Injectable } from '@angular/core';
import { of, Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Web3, Contract } from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import detectEthereumProvider from '@metamask/detect-provider';
import BigNumber from 'bignumber.js';
import { environment } from '../environments/environment';
import { EventBus } from './event-bus';
import { UserSessionProvider } from './user-session-provider';
import { NetworkNamePipe } from './pipes/networkName.pipe';
import networks from '../app/networks.data';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ExtraModuleInjector } from '../internal/services/decorator.service';
import { FMT_NUMBER } from 'web3';
import { FMT_BYTES } from 'web3';

import ERC20 from 'src/assets/abi/ERC20';
import IERC20 from 'src/assets/abi/IERC20';
import LOCKER from 'src/assets/abi/LOCKER';
import TIER_CALCULATOR from 'src/assets/abi/TIER_CALCULATOR';
import PANCAKE_ROUTER from 'src/assets/abi/PANCAKE_ROUTER';
import STAKE_MASTER from 'src/assets/abi/STAKE_MASTER';
import STAKING_POOL from 'src/assets/abi/STAKING_POOL';
import STACKING_PENALTY_POOL from 'src/assets/abi/STAKING_PENALTY_POOL';
import DEAL_LOCKUPS from 'src/assets/abi/DEAL_LOCKUPS';
import BLP_DEAL from 'src/assets/abi/BLP_DEAL';
import DEAL from 'src/assets/abi/DEAL';
import DEAL_COLLECT_WALLET from 'src/assets/abi/DEAL_COLLECT_WALLET';
import DEAL_VESTING from 'src/assets/abi/DEAL_VESTING';
import TGE_TOKEN_VESTING from 'src/assets/abi/TGE_TOKEN_VESTING';
import MERKLE_DISTRIBUTOR from 'src/assets/abi/MERKLE_DISTRIBUTOR';
import REFERRAL_REWARD from 'src/assets/abi/REFERRAL_REWARD';
import BONUS from 'src/assets/abi/BONUS'
import { CatchWrapper } from './tryCatchWrapper';
import { Web3PromiEvent } from 'web3-core';
import { SendTransactionEvents } from 'web3/lib/commonjs/eth.exports';

declare const window: any;

export class ChainError extends Error {
  constructor(message: any) {
    super(message);
    this.name = 'ChainError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  public MetamaskName: string = 'metamask';
  public WalletconnectName: string = 'walletconnect';
  public OkxWalletName: string = 'okx';
  public web3: Web3;

  private walletConnectProvider: WalletConnectProvider = new WalletConnectProvider(
    {
      rpc: {
        1: 'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        42: 'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        //BSC mainnet 56
        56: 'https://bsc-dataseed.binance.org/',
        //BSC testnet
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //Heco testnet
        256: 'https://http-testnet.hecochain.com',
        //Base mainnet
        8453: 'https://mainnet.base.org',
        //Base sepolia
        84532: 'https://sepolia.base.org'
      },
    }
  );

  private ethereumProvider: any;

  public get chainIdNumber(): number {
    return this.userSessionProvider.getChainId();
  }

  public get blpAddress(): string {
    return environment.bsc.blpAddress;

    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.blpAddress;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.blpAddress;
    //}
    //throw new Error('Unsupported chain');
  }

  public get refRewardsAddress(): string {
    return environment.bsc.refRewardsAddress;
  }

  public get getStackingAddress(): string {
    return environment.bsc.stackingAddress;
  }

  public get dealLockupsAddress(): string {
    return environment.bsc.dealLockupsAddress;
    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.dealLockupsAddress;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.dealLockupsAddress;
    //}
    //throw new Error('Unsupported chain');
  }

  public get dealCreatorAddress(): string {
    return environment.bsc.dealCreatorAddress;
    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.dealCreatorAddress;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.dealCreatorAddress;
    //}
    //throw new Error('Unsupported chain');
  }

  public get stakingPoolMasterAddress(): string {
    return environment.bsc.stakingPoolMasterAddress;
  }

  public get pancakeRouterAddress(): string {
    return environment.bsc.pancakeRouterAddress;
  }

  public get oldLockerAddress(): string {
    return environment.bsc.oldLockerAddress;
  }

  public get tierCalculatorAddress(): string {
    return environment.bsc.tierCalculatorAddress;
  }

  public get lockerAddress(): string {
    return environment.bsc.lockerAddress;

    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.lockerAddress;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.lockerAddress;
    //}
    //throw new Error('Unsupported chain');
  }

  public get bonusAddress(): string {
    return environment.bsc.bonusAddress;
  }

  constructor(
    private eventBus: EventBus,
    private userSessionProvider: UserSessionProvider,
    public translate: TranslateService
  ) {}

  public params(data: any): any {
    return { ...data, maxPriorityFeePerGas: 3000000000, maxFeePerGas: 3000000000 };
  }

  async initWeb3() {
    console.log('initWeb3');
    if(this.web3){
      return;
    }
    this.ethereumProvider = await detectEthereumProvider({ timeout: 500 });
    if (this.ethereumProvider) {
      this.web3 = new Web3(this.ethereumProvider);
      this.web3.setConfig({ contractDataInputFill: 'data' });
      var metamaskChainId = this.convertChainIdToHex(
        await this.web3.eth.getChainId({
          number: FMT_NUMBER.NUMBER,
          bytes: FMT_BYTES.HEX,
        })
      );
      // await window.ethereum.request({ method: 'eth_chainId' });
      console.log('matamask chainId: ' + metamaskChainId);
      if (parseInt(metamaskChainId, 16) != this.chainIdNumber) {
        this.setWeb3OnCustomRPC();
      }
      // TODO: that = this;
      // Reload when chain was changed in metamask (without connect wallet)
      var that = this;
      if (window.ethereum) {
        window.ethereum.on('chainChanged', function (chainId: string) {
          console.log(`chainChanged: ${chainId}`);
          let chainIdNumber = parseInt(chainId, 16);
          console.log('chainIdNumber: ' + chainIdNumber);
          if (chainIdNumber != that.chainIdNumber) {
            if (environment.supportedChains.indexOf(chainIdNumber) >= 0) {
              that.userSessionProvider.setChainId(chainIdNumber);
            } else {
              console.log('finishSession unsupported chain');
              // swal.fire({
              //   text: "Network was changed, please switch to BSC",
              //   icon: 'warning',
              //   onClose: () => {location.reload()}
              // });
              that.userSessionProvider.finishSession();
              // that.userSessionProvider.setChainId(-1);
              return;
            }
          }
          if (
            typeof window.ethereum !== 'undefined' &&
            window.ethereum.isTrust
          ) {
            console.log('User is using Trust Wallet');
          } else {
            location.reload();
          }
        });
      }
      if (window.okxwallet) {
        window.okxwallet.on('chainChanged', function (chainId: string) {
          console.log(`chainChanged: ${chainId}`);
          let chainIdNumber = parseInt(chainId, 16);
          console.log('chainIdNumber: ' + chainIdNumber);
          if (chainIdNumber != that.chainIdNumber) {
            if (environment.supportedChains.indexOf(chainIdNumber) >= 0) {
              that.userSessionProvider.setChainId(chainIdNumber);
            } else {
              const translateService = ExtraModuleInjector.get<TranslateService>(
                TranslateService
              );
              console.log('finishSession unsupported chain');
              swal.fire({
                text: translateService.instant(
                  'networkWasChangedPleaseSwitchToBsc'
                ),
                icon: 'warning',
                onClose: () => {
                  location.reload();
                },
              });
              that.userSessionProvider.finishSession();
              return;
            }
          }
        });
      }
      return;
    } else {
      //this.isWeb3Disabled = true;
      if (!this.web3 || !this.web3.currentProvider) {
        this.setWeb3OnCustomRPC();
      }
    }

    //await this.updateChanId();

    //this.web3 = new Web3("https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b");
    //this.chainId = '0x2a';

    //this.chainId = '0x01';

    //await this.WalletConnect();
  }

  private setWeb3OnCustomRPC() {
    console.log(`set custom RPC for web3. ChainId: ${this.chainIdNumber}`);
    //ETH Mainnet
    if (this.chainIdNumber == 1) {
      this.web3 = new Web3(
        'https://mainnet.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898'
      );
    }
    //Kovan
    else if (this.chainIdNumber == 42) {
      this.web3 = new Web3(
        'https://kovan.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898'
      );
    }
    //BSC
    else if (this.chainIdNumber == 56) {
      this.web3 = new Web3('https://bsc-dataseed.binance.org/');
    }
    //BSC Testnet
    else if (this.chainIdNumber == 97) {
      this.web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');
    }
    //Heco Testnet
    else if (this.chainIdNumber == 256) {
      this.web3 = new Web3('https://http-testnet.hecochain.com');
    }
  }

  //#region unlock wallet

  //async unlockWallet(): Promise<void> {
  public async unlockWalletconnect(reload = false): Promise<string> {
    var data: any = await this.WalletConnect();
    //this.account = data[0];
    this.userSessionProvider.linkWallet(data[0], this.WalletconnectName);
    this.eventBus.loginEvent.emit(data[0]);
    if (reload) {
      location.reload();
    }
    return data[0];
  }
  public async unlockOkx(reload = false) {
    if (typeof window.okxwallet == 'undefined') {
      throw new ChainError('OKX must be installed');
    }
    this.web3 = new Web3(window.okxwallet as any);

    await window.okxwallet.request({ method: 'eth_requestAccounts' });
    let chainId = await window.okxwallet.request({ method: 'eth_chainId' });
    let chainIdNumber = parseInt(chainId, 16);

    if (this.chainIdNumber != chainIdNumber) {
      var toNetwork = networks.find((n) => n.chainId == this.chainIdNumber);
      if (toNetwork.networkParams) {
        try {
          await window.okxwallet.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: toNetwork.networkParams.chainId }],
          });
          return true;
        } catch (switchError: any) {
          if (switchError.code === 4902) {
            try {
              await window.okxwallet.request({
                method: 'wallet_addEthereumChain',
                params: [toNetwork.networkParams],
              });
            } catch (addError) {
              console.error(addError);
              this.userSessionProvider.finishSession();
            }
          }
        }
      } else {
        this.userSessionProvider.finishSession();
        throw new ChainError(
          `Select ${new NetworkNamePipe().transform(
            this.chainIdNumber
          )} Network in your wallet.`
        );
      }
    }

    window.okxwallet.enable().then(
      (data: any) => {
        if (data.length > 0) {
          this.userSessionProvider.linkWallet(data[0], this.OkxWalletName);
          this.eventBus.loginEvent.emit(data[0]);
          var that = this;
          if (window.okxwallet) {
            window.okxwallet.on(
              'accountsChanged',
              function (accounts: string[]) {
                console.log('accountsChanged');
                console.log(accounts);
                if (
                  (that.userSessionProvider.authEth &&
                    accounts.length > 0 &&
                    that.userSessionProvider.authEth != accounts[0]) ||
                  accounts.length == 0
                ) {
                  that.userSessionProvider.finishAuth();
                }
                location.reload();
              }
            );
          }
          if (reload) {
            location.reload();
          }
        }
      },
      (reason: any) => {
        console.log('My Permission to connect to Okx was denied');
        console.log(reason);
      }
    );
    return true;
  }

  public async unlockMetamask(reload = false) {
    console.log('unlockMetamask');
    if (typeof window.ethereum == 'undefined') {
      //this.translate.get('MetaMask must be installed').subscribe((langResp: string) => {
      throw new ChainError('MetaMask must be installed');
      //});
      return false;
    }

    await window.ethereum.request({ method: 'eth_requestAccounts' });
    let chainId = await window.ethereum.request({ method: 'eth_chainId' });
    ////  Get Chain Id
    //TODO: check is this work in wallets
    //var walletChainIdNumber = await this.web3.eth.getChainId();

    let chainIdNumber = parseInt(chainId, 16);
    console.log('chainId: ' + chainId);
    console.log('chainIdNumber: ' + chainIdNumber);
    console.log('web3Service chainId: ' + this.chainIdNumber);

    if (this.chainIdNumber != chainIdNumber) {
      var toNetwork = networks.find((n) => n.chainId == this.chainIdNumber);
      if (toNetwork.networkParams) {
        try {
          // @ts-ignore
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: toNetwork.networkParams.chainId }],
          });
          return true;
        } catch (switchError: any) {
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [toNetwork.networkParams],
              });
            } catch (addError) {
              console.error(addError);
              this.userSessionProvider.finishSession();
            }
          }
        }
      } else {
        this.userSessionProvider.finishSession();
        throw new ChainError(
          `Select ${new NetworkNamePipe().transform(
            this.chainIdNumber
          )} Network in your wallet.`
        );
      }
    }

    //if (environment.production) {
    //    if (chainId != '0x01' && chainId != '0x1' && chainId != '0x38') {
    //        this.showErrorModal(`Select Mainnet or BSC Network in MetaMask.`);
    //        //this.translate.get('select_right_metamask_network').subscribe((langResp: string) => {
    //        //    this.showErrorModal(langResp);
    //        //});
    //        return false;
    //    }
    //}
    //else {
    //    console.log(chainId);
    //    if (chainId != '0x2a' && chainId != '0x61') {
    //        this.showErrorModal(`Select Kovan or BSC Test Network in MetaMask.`);
    //        return false;
    //    }
    //}

    window.ethereum.enable().then(
      (data: any) => {
        console.log('enabled');
        console.log(data);

        if (data.length > 0) {
          //this.account = data[0];
          this.userSessionProvider.linkWallet(data[0], this.MetamaskName);
          this.eventBus.loginEvent.emit(data[0]);

          //TOOD: that = this;
          var that = this;
          if (window.ethereum) {
            window.ethereum.on(
              'accountsChanged',
              function (accounts: string[]) {
                console.log('accountsChanged');
                console.log(accounts);
                if (
                  (that.userSessionProvider.authEth &&
                    accounts.length > 0 &&
                    that.userSessionProvider.authEth != accounts[0]) ||
                  accounts.length == 0
                ) {
                  that.userSessionProvider.finishAuth();
                }
                location.reload();
              }
            );
            //window.ethereum.on('chainChanged', function (chainId: string) {
            //  console.log('chainChanged');
            //  console.log(chainId);
            //  if (chainId === "0x1")
            //    chainId = "0x01";
            //  if (chainId != that.chainId) {
            //    //if new chain is Ethereum
            //    if (chainId === '0x01' || chainId === '0x2a') {
            //      that.userSessionProvider.setETHNetwork();
            //    }
            //    //if new chain is BSC
            //    else if (chainId === '0x38' || chainId === '0x61') {
            //      that.userSessionProvider.setBSCNetwork();
            //    }
            //  }

            //  location.reload();
            //})
          }

          //TODO: remove reload, add eventBus
          if (reload) {
            location.reload();
          }
        }
      },
      (reason: any) => {
        console.log('My Permission to connect to Metamask was denied');
        console.log(reason);
      }
    );

    return true;
  }

  //#endregion

  async WalletConnect() {
    console.log('WalletConnect');
    //  Create WalletConnect Provider
    this.walletConnectProvider = new WalletConnectProvider({
      rpc: {
        1: 'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        42: 'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        // BSC mainnet 56
        56: 'https://bsc-dataseed.binance.org/',
        // BSC testnet
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        // heco testnet
        256: 'https://http-testnet.hecochain.com',
        //Base mainnet
        8453: 'https://mainnet.base.org',
        //Base sepolia
        84532: 'https://sepolia.base.org'
      },
    });
    console.log(`chainIdNumber: ${this.chainIdNumber}`);
    this.walletConnectProvider.chainId = this.chainIdNumber;

    //  Enable session (triggers QR Code modal)
    let addresses = await this.walletConnectProvider.enable();
    console.log(addresses);

    //  Create Web3
    this.web3 = new Web3(this.walletConnectProvider as any);

    //  Get Chain Id
    const walletChainIdNumber = await this.web3.eth.getChainId({
      number: FMT_NUMBER.NUMBER,
      bytes: FMT_BYTES.HEX,
    });
    console.log('Wallet connect chainId: ' + walletChainIdNumber);
    if (this.chainIdNumber != walletChainIdNumber) {
      throw new ChainError(
        `Select ${new NetworkNamePipe().transform(
          this.chainIdNumber
        )} Network in your wallet.`
      );
      //this.userSessionProvider.finishSession();
    }

    // Subscribe to accounts change
    this.walletConnectProvider.on('accountsChanged', (accounts: string[]) => {
      console.log('accountsChanged ' + accounts);
      if (
        (this.userSessionProvider.authEth &&
          accounts.length > 0 &&
          this.userSessionProvider.authEth != accounts[0]) ||
        accounts.length == 0
      ) {
        this.userSessionProvider.finishAuth();
      }
      location.reload();
      this.eventBus.accountsChanged.emit(accounts);
    });

    // Subscribe to chainId change
    this.walletConnectProvider.on('chainChanged', (chainId: number) => {
      console.log('chainChanged' + chainId);

      this.eventBus.chainChanged.emit(this.convertChainIdToHex(chainId));
    });

    // Subscribe to session connection
    this.walletConnectProvider.on('connect', () => {
      console.log('connect');
      this.eventBus.walletConnect.emit('');
    });

    // Subscribe to session disconnection
    this.walletConnectProvider.on(
      'disconnect',
      (code: number, reason: string) => {
        console.log(code, reason);
        this.eventBus.walletDisconnect.emit(reason);
      }
    );
    //console.log(this.web3);
    return addresses;
  }

  public convertChainIdToHex(value: number): string {
    var hexChainId = '0x' + value.toString(16);
    if (hexChainId === '0x1') hexChainId = '0x01';
    return hexChainId;
  }

  async WalletDisconnect() {
    if (this.walletConnectProvider) {
      // Close provider session
      await this.walletConnectProvider.disconnect();
    }
  }

  @CatchWrapper
  async PersonalSign(dataToSign: string, address: string): Promise<any> {
    return this.web3.eth.personal.sign(dataToSign, address, '');
  }
  //#region web3

  //#region ERC20BasicAbi

  @CatchWrapper
  async GetTransactionReceipt(tx: string): Promise<any> {
    return this.web3.eth.getTransactionReceipt(tx);
  }

  @CatchWrapper
  async GetDecimals(contractAddress: string): Promise<any> {
    return new Contract(ERC20, contractAddress, this.web3).methods
      .decimals()
      .call();
  }

  @CatchWrapper
  async GetTotalSupply(contractAddress: string): Promise<any> {
    const contract = new this.web3.eth.Contract(ERC20, contractAddress);
    return contract.methods.totalSupply().call();
  }

  @CatchWrapper
  async GetAllowance(
    account: string,
    tokenForspend: string,
    forContractAddress: string
  ): Promise<any> {
    const contract = new this.web3.eth.Contract(ERC20, tokenForspend);
    return contract.methods.allowance(account, forContractAddress).call();
  }

  @CatchWrapper
  async GetTokenBalance(account: string, tokenAddress: string): Promise<any> {
    console.log('get token balance', account, tokenAddress);
    return new Contract(ERC20, tokenAddress, this.web3).methods
      .balanceOf(account)
      .call();
  }

  @CatchWrapper
  async GetContractSymbol(tokenAddress: string): Promise<any> {
    return new Contract(ERC20, tokenAddress, this.web3).methods.symbol().call();
  }

  @CatchWrapper
  async GetContractName(tokenAddress: string): Promise<any> {
    return new Contract(ERC20, tokenAddress, this.web3).methods.name().call();
  }

  approve(account: string, tokenForspend: string, forContractAddress: string) {
    // new contract
    // DOC: https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#new-contract
    const contract = new Contract(IERC20, tokenForspend, this.web3);
    const contractEventSource = contract.methods
      .approve(
        forContractAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
      .send(this.params({ from: account }));
    return contractEventSourceToObserves(contractEventSource);
  }

  approveForDeal(account: string, tokenForSpend: string, forContractAddress: string, usersTicketSize: any) {
    // new contract
    // DOC: https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#new-contract
    const contract = new Contract(IERC20, tokenForSpend, this.web3);
    return contract.methods
      .approve(
        forContractAddress,
        usersTicketSize
      )
      .send(this.params({from: account}));
  }

  @CatchWrapper
  async getEthBalance(customerAddress: string): Promise<any> {
    return this.web3.eth.getBalance(customerAddress);
  }

  //#endregion ERC20BasicAbi

  //#region LockerAbi

  @CatchWrapper
  async getLockedTokenAmount(
    contractAddress: string,
    user: string
  ): Promise<string> {
    return new Contract(LOCKER, contractAddress, this.web3).methods
      .getLockedBLP(user)
      .call();
  }

  @CatchWrapper
  async getLockerPenaltyBP(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(LOCKER, contractAddress, this.web3).methods
      .getPenaltyBP(userAddress)
      .call();
  }

  lockerDeposit(account: string, tokenAmount: number, decimal: number) {
    const stringTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new Contract(LOCKER, this.lockerAddress, this.web3);
    const contractEventSource = contract.methods
      .deposit(stringTokenAmount)
      .send(this.params({ from: account }));
    return contractEventSourceToObserves(contractEventSource);
  }

  lockerWithdraw(account: string, tokenAmount: number, decimal: number) {
    const stringTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new Contract(LOCKER, this.lockerAddress, this.web3);
    const contractEventSource = contract.methods
      .withdraw(stringTokenAmount)
      .send(this.params({ from: account }));
    return contractEventSourceToObserves(contractEventSource);
  }

  lockerTransfer(
    account: string,
    poolAddress: string,
    tokenAmount: number,
    decimal: number
  ) {
    const stringTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new Contract(LOCKER, this.lockerAddress, this.web3);
    const contractEventSource = contract.methods
      .transferToPool(poolAddress, stringTokenAmount)
      .send(this.params({ from: account }));
    return contractEventSourceToObserves(contractEventSource);
  }

  lockerOldWithdraw(account: string, tokenAmount: number, decimal: number) {
    const stringTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new Contract(LOCKER, this.lockerAddress, this.web3);
    const contractEventSource = contract.methods
      .withdraw(stringTokenAmount)
      .send(this.params({ from: account }));
    return contractEventSourceToObserves(contractEventSource);
  }

  @CatchWrapper
  async getLockerPenalties(index: number): Promise<string> {
    return new Contract(LOCKER, this.lockerAddress, this.web3).methods
      .allPenalties(index)
      .call();
  }

  //#endregion LockerAbi

  //#region TierCalculator
  @CatchWrapper
  async getLockedTokenAmountTierCalculator(
    contractAddress: string,
    user: string
  ): Promise<string> {
    return new Contract(TIER_CALCULATOR, contractAddress, this.web3).methods
      .getLockedTokens(user)
      .call();
  }

  @CatchWrapper
  async getUserLockingStart(user: string): Promise<string> {
    return new Contract(
      TIER_CALCULATOR,
      this.tierCalculatorAddress,
      this.web3
    ).methods
      .userLockingStarts(user)
      .call();
  }

  @CatchWrapper
  async getDealUsersTierIndex(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const contract = new Contract(
        TIER_CALCULATOR,
        this.tierCalculatorAddress,
        this.web3
      );
      contract.methods
        .getTierIndex(userAddress, contractAddress)
        .call()
        .then((resp) => resolve(resp));
    }) as Promise<any>;
  }

  @CatchWrapper
  async getDealUsersBonusTierIndex(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const contract = new Contract(
        TIER_CALCULATOR,
        this.tierCalculatorAddress,
        this.web3
      );
      contract.methods
        .getBonusTierIndex(userAddress, contractAddress)
        .call()
        .then((resp) => resolve(resp));
    }) as Promise<any>;
  }

  @CatchWrapper
  async getBonusInterval(): Promise<string> {
    return new Contract(
      TIER_CALCULATOR,
      this.tierCalculatorAddress,
      this.web3
    ).methods
      .bonusInterval()
      .call();
  }

  //#endregion TierCalculator

  //#region PancakeRouterAbi
  @CatchWrapper
  async getAmountsOut(amountIn: number, path: string[]): Promise<any> {
    const stringAmountIn = '0x' + new BigNumber(amountIn).toString(16);
    return new Contract(
      PANCAKE_ROUTER,
      this.pancakeRouterAddress,
      this.web3
    ).methods
      .getAmountsOut(stringAmountIn, path)
      .call();
  }

  @CatchWrapper
  async WETH(): Promise<any> {
    return new Contract(
      PANCAKE_ROUTER,
      this.pancakeRouterAddress,
      this.web3
    ).methods
      .WETH()
      .call();
  }
  //#endregion PancakeRouterAbi

  //#region StakeMasterAbi

  @CatchWrapper
  async GetStakeMasterFeeAmount(): Promise<number> {
    return new Contract(
      STAKE_MASTER,
      this.stakingPoolMasterAddress,
      this.web3
    ).methods
      .feeAmount()
      .call();
  }

  @CatchWrapper
  async GetStakeMasterFeeToken(): Promise<string> {
    return new Contract(
      STAKE_MASTER,
      this.stakingPoolMasterAddress,
      this.web3
    ).methods
      .feeToken()
      .call();
  }

  createStakingPool(
    userAddress: string,
    stakingToken: string,
    poolToken: string,
    startTime: number,
    finishTime: number,
    poolTokenAmount: number,
    poolTokenDecimals: number,
    hasWhiteListing: boolean,
    depositFeeBP: number,
    feeTo: string,
    msgValue: number
  ) {
    const contract = new Contract(
      STAKE_MASTER,
      this.stakingPoolMasterAddress,
      this.web3
    );
    const stringStartTime = '0x' + new BigNumber(startTime).toString(16);
    const stringFinishTime = '0x' + new BigNumber(finishTime).toString(16);
    // const stringPoolTokenAmount = "0x" + new BigNumber(poolTokenAmount).toString(16);
    const stringDepositFeeBP = '0x' + new BigNumber(depositFeeBP).toString(16);
    const stringPoolTokenAmount =
      '0x' + new BigNumber(poolTokenAmount).shiftedBy(18).toString(16);

    //   function createStakingPool(
    //     IERC20 _stakingToken,
    //     IERC20 _poolToken,
    //     uint256 _startTime,
    //     uint256 _finishTime,
    //     uint256 _poolTokenAmount,
    //     bool _hasWhitelisting,
    //     uint256 _depositFeeBP,
    //     address _feeTo
    // )

    const contractEventSource = contract.methods
      .createStakingPool(
        stakingToken,
        poolToken,
        stringStartTime,
        stringFinishTime,
        stringPoolTokenAmount,
        hasWhiteListing,
        stringDepositFeeBP,
        feeTo
      )
      .send(this.params({ from: userAddress, value: msgValue }));

    return contractEventSourceToObserves(contractEventSource);
  }
  //#endregion StakeMasterAbi

  //#region StakingPoolAbi

  @CatchWrapper
  async rewardPerSec(address: string): Promise<any> {
    return new Contract(STAKING_POOL, address, this.web3).methods
      .rewardPerSec()
      .call();
  }

  @CatchWrapper
  async allStakedAmount(address: string): Promise<any> {
    return new Contract(STAKING_POOL, address, this.web3).methods
      .allStakedAmount()
      .call();
  }

  isWhitelisted(userAddress: string, poolAddress: string) {
    return new Contract(STAKING_POOL, poolAddress, this.web3).methods
      .isWhitelisted(userAddress)
      .call();
  }

  depositToPool(
    userAddress: string,
    tokenAmount: number,
    tokenDecimals: number,
    poolAddress: string,
    referrerAddress: string = '0x0000000000000000000000000000000000000000'
  ) {
    const bnTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
    const poolContract = new Contract(STAKING_POOL, poolAddress, this.web3);
    const contractEventsSource = poolContract.methods
      .stakeTokens(bnTokenAmount, referrerAddress)
      .send(this.params({ from: userAddress }));
    return contractEventSourceToObserves(contractEventsSource);
  }

  withdrawFromPool(
    userAddress: string,
    tokenAmount: number,
    tokenDecimals: number,
    poolAddress: string
  ) {
    const bnTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
    const poolContract = new Contract(STAKING_POOL, poolAddress, this.web3);
    const contractEventsSource = poolContract.methods
      .withdrawStake(bnTokenAmount)
      .send(this.params({ from: userAddress }));
    return contractEventSourceToObserves(contractEventsSource);
  }

  @CatchWrapper
  async getPoolUserInfo(
    userAddress: string,
    poolAddress: string
  ): Promise<any> {
    return new Contract(STAKING_POOL, poolAddress, this.web3).methods
      .getUserInfo(userAddress)
      .call();
  }

  @CatchWrapper
  async getPoolPendingReward(
    contractAddress: string,
    user: string
  ): Promise<string> {
    return new Contract(STAKING_POOL, contractAddress, this.web3).methods
      .pendingReward(user)
      .call();
  }

  @CatchWrapper
  async getPoolPenaltyBP(startTime: number, poolAddress: string): Promise<any> {
    return new Contract(STACKING_PENALTY_POOL, poolAddress, this.web3).methods
      .getPenaltyBP(startTime)
      .call();
  }

  @CatchWrapper
  async getPoolPenalties(index: number, poolAddress: string): Promise<any> {
    return new Contract(STACKING_PENALTY_POOL, poolAddress, this.web3).methods
      .allPenalties(index)
      .call();
  }

  //#endregion StakingPoolAbi

  //#region DealLockupsAbi
  @CatchWrapper
  async getDealLockupsTiersLength(): Promise<string> {
    return new Contract(
      DEAL_LOCKUPS,
      this.dealLockupsAddress,
      this.web3
    ).methods
      .getTiersLength()
      .call();
  }

  @CatchWrapper
  async getDealLockupsTiers(index: number): Promise<string> {
    return new Contract(
      DEAL_LOCKUPS,
      this.dealLockupsAddress,
      this.web3
    ).methods
      .allTiers(index)
      .call();
  }

  @CatchWrapper
  async getLastParticipations(userAddress: string): Promise<string> {
    return new Contract(
      DEAL_LOCKUPS,
      this.dealLockupsAddress,
      this.web3
    ).methods
      .lastParticipations(userAddress)
      .call();
  }
  //#endregion DealLockupsAbi

  //#region DealAbi
  @CatchWrapper
  async getDealPaymentToken(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .paymentToken()
      .call();
  }

  @CatchWrapper
  async getDealTokenPrice(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .tokenPrice()
      .call();
  }

  @CatchWrapper
  async getDealRewardToken(contractAddress: string): Promise<string> {
    const contract = new Contract(DEAL, contractAddress, this.web3);
    return contract.methods.rewardToken().call();
  }

  @CatchWrapper
  async getDealDecimals(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .decimals()
      .call();
  }

  @CatchWrapper
  async getDealStartTimestamp(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .startTimestamp()
      .call();
  }

  @CatchWrapper
  async getDealFinishTimestamp(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .finishTimestamp()
      .call();
  }

  @CatchWrapper
  async getDealStartClaimTimestamp(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .startClaimTimestamp()
      .call();
  }

  @CatchWrapper
  async getDealMaxDistributedTokenAmount(
    contractAddress: string
  ): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .maxDistributedTokenAmount()
      .call();
  }

  @CatchWrapper
  async getDealTotalRaise(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .totalRaise()
      .call();
  }

  @CatchWrapper
  async getDealTokensForDistribution(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .tokensForDistribution()
      .call();
  }

  @CatchWrapper
  async getDealMinimumRaise(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .minimumRaise()
      .call();
  }

  @CatchWrapper
  async getDealDistributedTokens(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .distributedTokens()
      .call();
  }

  @CatchWrapper
  async getDealAllowRefund(contractAddress: string): Promise<string> {
    return new Contract(BLP_DEAL, contractAddress, this.web3).methods
      .allowRefund()
      .call();
  }

  @CatchWrapper
  async getDealTiersLength(contractAddress: string): Promise<string> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .getTiersLength()
      .call();
  }

  @CatchWrapper
  async getDealTiers(contractAddress: string, index: number): Promise<any> {
    return new Contract(DEAL_COLLECT_WALLET, contractAddress, this.web3).methods
      .allTiers(index)
      .call();
  }

  @CatchWrapper
  async getDealTiersOld(contractAddress: string, index: number): Promise<any> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .allTiers(index)
      .call();
  }

  @CatchWrapper
  async getVestingPercent(contractAddress: string): Promise<any> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .vestingPercent()
      .call();
  }

  @CatchWrapper
  async getDealSpecialTiersFeePercent(contractAddress: string): Promise<any> {
    return new Contract(DEAL_COLLECT_WALLET, contractAddress, this.web3).methods
      .specialTiersFeePercent()
      .call();
  }

  @CatchWrapper
  async getDealVestingAddress(contractAddress: string): Promise<any> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .dealVesting()
      .call();
  }

  @CatchWrapper
  async getDealUserInfo(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .userInfo(userAddress)
      .call();
  }

  async getDealUserCustomRaise(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .customRaises(userAddress)
      .call();
  }

  dealPay(
    contractAddress: string,
    userAddress: string,
    amountWithDecimals: string,
    signature: string,
    payByETH: boolean
  ) {
    //let stringTokenAmount = "0x" + new BigNumber(amount).shiftedBy(decimals).toString(16);
    let wei = payByETH ? amountWithDecimals : 0;

    const contract = new Contract(DEAL, contractAddress, this.web3);

    //pay(uint256 _amount, bytes memory _signature) payable
    const contractEventSource = contract.methods
      .pay(amountWithDecimals, signature)
      .send(this.params({ value: wei, from: userAddress }));

    return contractEventSourceToObserves(contractEventSource);
  }

  dealClaim(contractAddress: string, userAddress: string) {
    const contract = new Contract(DEAL, contractAddress, this.web3);

    const contractEventSource = contract.methods
      .claim()
      .send(this.params({ from: userAddress }));

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion DealAbi

  //#region DealCollectWalletAbi
  dealPayWithEmissionAddress(
    contractAddress: string,
    userAddress: string,
    emissionAddress: string,
    amountWithDecimals: string,
    signature: string,
    payByETH: boolean
  ) {
    //let stringTokenAmount = "0x" + new BigNumber(amount).shiftedBy(decimals).toString(16);
    let wei = payByETH ? amountWithDecimals : 0;

    const contract = new Contract(
      DEAL_COLLECT_WALLET,
      contractAddress,
      this.web3
    );

    //pay(uint256 _amount, string memory _wallet, bytes memory _signature)
    const contractEventSource = contract.methods
      .pay(amountWithDecimals, emissionAddress, signature)
      .send(this.params({ value: wei, from: userAddress }));

    return contractEventSourceToObserves(contractEventSource);
  }
  //#endregion DealCollectWalletAbi

  //#region DealVestingAbi

  @CatchWrapper
  async getVVestingStart(contractAddress: string): Promise<any> {
    return new Contract(DEAL_VESTING, contractAddress, this.web3).methods
      .vestingStart()
      .call();
  }

  @CatchWrapper
  async getVVestingInterval(contractAddress: string): Promise<any> {
    return new Contract(DEAL_VESTING, contractAddress, this.web3).methods
      .vestingInterval()
      .call();
  }

  @CatchWrapper
  async getVVestingDuration(contractAddress: string): Promise<any> {
    return new Contract(DEAL_VESTING, contractAddress, this.web3).methods
      .vestingDuration()
      .call();
  }

  @CatchWrapper
  async getVestingReleasableAmount(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(DEAL_VESTING, contractAddress, this.web3).methods
      .releasableAmount(userAddress)
      .call();
  }

  @CatchWrapper
  async getVestingForUser(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(DEAL_VESTING, contractAddress, this.web3).methods
      .vestings(userAddress)
      .call();
  }

  vestingRelease(contractAddress: string, userAddress: string) {
    const contract = new Contract(DEAL_VESTING, contractAddress, this.web3);

    const contractEventSource = contract.methods
      .release(userAddress)
      .send(this.params({ from: userAddress }));

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion DealVestingAbi

  //#region BLPDealAbi

  @CatchWrapper
  async getBLPVestingStart(contractAddress: string): Promise<any> {
    return new Contract(BLP_DEAL, contractAddress, this.web3).methods
      .vestingStart()
      .call();
  }

  @CatchWrapper
  async getBLPVestingInterval(contractAddress: string): Promise<any> {
    return new Contract(BLP_DEAL, contractAddress, this.web3).methods
      .vestingInterval()
      .call();
  }

  @CatchWrapper
  async getBLPVestingDuration(contractAddress: string): Promise<any> {
    return new Contract(BLP_DEAL, contractAddress, this.web3).methods
      .vestingDuration()
      .call();
  }

  @CatchWrapper
  async getBLPReleasableAmount(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(BLP_DEAL, contractAddress, this.web3).methods
      .releasableAmount(userAddress)
      .call();
  }

  @CatchWrapper
  async getBLPVestingForUser(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(BLP_DEAL, contractAddress, this.web3).methods
      .vestings(userAddress)
      .call();
  }

  blpDealRelease(contractAddress: string, userAddress: string) {
    const contract = new Contract(BLP_DEAL, contractAddress, this.web3);

    const contractEventSource = contract.methods
      .release(userAddress)
      .send(this.params({ from: userAddress }));

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion BLPDealAbi

  //#region TGETokenVestingAbi

  @CatchWrapper
  async getTGEVestingStart(contractAddress: string): Promise<any> {
    return new Contract(TGE_TOKEN_VESTING, contractAddress, this.web3).methods
      .start()
      .call();
  }

  @CatchWrapper
  async getTGEVestingInterval(contractAddress: string): Promise<any> {
    return new Contract(TGE_TOKEN_VESTING, contractAddress, this.web3).methods
      .interval()
      .call();
  }

  @CatchWrapper
  async getTGEVestingDuration(contractAddress: string): Promise<any> {
    return new Contract(TGE_TOKEN_VESTING, contractAddress, this.web3).methods
      .duration()
      .call();
  }

  @CatchWrapper
  async getTGEVestingTgeTime(contractAddress: string): Promise<any> {
    return new Contract(TGE_TOKEN_VESTING, contractAddress, this.web3).methods
      .tgeTime()
      .call();
  }

  @CatchWrapper
  async getTGEVestingTgePercent(contractAddress: string): Promise<any> {
    return new Contract(TGE_TOKEN_VESTING, contractAddress, this.web3).methods
      .tgePercent()
      .call();
  }

  @CatchWrapper
  async getTGEReleasableAmount(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(TGE_TOKEN_VESTING, contractAddress, this.web3).methods
      .releasableAmount(userAddress)
      .call();
  }

  @CatchWrapper
  async getTGEVestingForUser(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Contract(TGE_TOKEN_VESTING, contractAddress, this.web3).methods
      .vestings(userAddress)
      .call();
  }

  TGEReleaseTGE(contractAddress: string, userAddress: string) {
    const contract = new Contract(
      TGE_TOKEN_VESTING,
      contractAddress,
      this.web3
    );

    const contractEventSource = contract.methods
      .releaseTGE(userAddress)
      .send(this.params({ from: userAddress }));

    return contractEventSourceToObserves(contractEventSource);
  }

  TGERelease(contractAddress: string, userAddress: string) {
    const contract = new Contract(
      TGE_TOKEN_VESTING,
      contractAddress,
      this.web3
    );

    const contractEventSource = contract.methods
      .release(userAddress)
      .send(this.params({ from: userAddress }));

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion TGETokenVestingAbi

  //#region MerkleDistributorAbi

  @CatchWrapper
  async getClaimingDealToken(contractAddress: string): Promise<string> {
    return new Contract(MERKLE_DISTRIBUTOR, contractAddress, this.web3).methods
      .token()
      .call();
  }

  @CatchWrapper
  async isPausedClaimingDeal(contractAddress: string): Promise<string> {
    return new Contract(MERKLE_DISTRIBUTOR, contractAddress, this.web3).methods
      .paused()
      .call();
  }

  claimTokensClaimingDeal(
    account: string,
    contractAddress: string,
    index: number,
    emissionAddress: string,
    amount: string,
    merkleProofs: string[]
  ) {
    const masterContract = new Contract(
      MERKLE_DISTRIBUTOR,
      contractAddress,
      this.web3
    );
    const contractEventSource = masterContract.methods
      .claim(index, emissionAddress, amount, merkleProofs)
      .send(this.params({ from: account }));
    return contractEventSourceToObserves(contractEventSource);
  }
  //#endregion MerkleDistributorAbi

  //#region BonusAbi

  @CatchWrapper
  async getBonusAmount(
    contractAddress: string,
    user: string
  ): Promise<string> {
    return new Contract(BONUS, contractAddress, this.web3).methods
      .balances(user)
      .call();
  }

  //#endregion BonusAbi

  //#endregion web3
  @CatchWrapper
  public async getAllTiers(): Promise<any[]> {
    let allTiers: any[] = [];
    let tiersLength = parseInt(await this.getDealLockupsTiersLength());
    for (let i = 0; i < tiersLength; i++) {
      let tier = await this.getDealLockupsTiers(i);
      allTiers.push(tier);
    }

    return allTiers;
  }

  @CatchWrapper
  public async calcUserTierIndex(address: string, allTiers: any[] = null) {
    let userTierIndex = -1;
    if (allTiers === null) {
      allTiers = await this.getAllTiers();
    }

    let lockedTotalBalance = await this.getLockedTokenAmountTierCalculator(
      this.tierCalculatorAddress,
      address
    );
    let lockedBalanceBn = new BigNumber(lockedTotalBalance);
    let tierIndex = 0;

    let success = false;
    for (let i = 0; i < allTiers.length; i++) {
      if (
        allTiers[i] &&
        lockedBalanceBn.isGreaterThanOrEqualTo(
          new BigNumber(allTiers[i].blpAmount)
        ) &&
        new BigNumber(allTiers[i].blpAmount).isGreaterThanOrEqualTo(
          new BigNumber(allTiers[tierIndex].blpAmount)
        )
      ) {
        tierIndex = i;
        success = true;
      }
    }

    if (success) {
      userTierIndex = tierIndex;
    }

    return userTierIndex;
  }

  @CatchWrapper
  public async getCustomTier(contractAddress: string, userAddress: string) {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .getCustomTierIndex(userAddress)
      .call();
  }

  @CatchWrapper
  public async checkCustomTiers(contractAddress: string, userAddress: string): Promise<number> {
    return new Contract(DEAL, contractAddress, this.web3).methods
      .customTiers(userAddress)
      .call();
  }

  @CatchWrapper
  public async claimRefRewards(account: string) {
    return new Contract(REFERRAL_REWARD, environment.bsc.refRewardsAddress, this.web3).methods
      .withdrawReward()
      .send(this.params({ from: account }));
  }

  @CatchWrapper
  public async getRewardsAmount(account: string): Promise<BigNumber> {
    console.log('getRewardsAmount', account);
    return new Contract(
      REFERRAL_REWARD,
      environment.bsc.refRewardsAddress,
      this.web3
    ).methods
      .balances(account)
      .call();
  }
}

function contractEventSourceToObserves(contractEventSource: any) {
  const transactionHashSbj: Subject<string> = new Subject();
  const receiptSbj: Subject<any> = new Subject();
  const errorSbj: Subject<{
    error: any;
    receipt: any;
  }> = new Subject();

  contractEventSource
    .on('error', function (error: any, receipt: any) {
      console.log('contractEventSource error', error, receipt);
      errorSbj.error({ error, receipt });
      errorSbj.complete();
    })
    .on('transactionHash', function (hash: any) {
      transactionHashSbj.next(hash);
      transactionHashSbj.complete();
    })
    .on('receipt', function (receipt: any) {
      receiptSbj.next(receipt);
      receiptSbj.complete();
    })
    .catch((e: any) => {
      console.log('error', e);
      errorSbj.error({ e });
      errorSbj.complete();
    });

  const transactionHash$ = transactionHashSbj
    .asObservable()
    .pipe(takeUntil(errorSbj));

  const receipt$ = receiptSbj.asObservable().pipe(takeUntil(errorSbj));

  const error$ = errorSbj.asObservable();

  return {
    transactionHash$,
    receipt$,
    error$,
  };
}
